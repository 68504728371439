import React from "react"
import { Link } from "gatsby"
import {
  categoryList,
  categoryListTitle,
} from "./category-list.module.css"
import linkBuilder from "../../modules/linkBuilder"

const CategoryList = ({ categories, title, showDesk }) => {
  return (
    <div className={categoryList}>
      <p className={categoryListTitle}>{title}</p>
      <ul>
        {categories
          .sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })
          .map((category, index) => {
            let name = category.name
            if (showDesk) name = `${category.desk.name} / ${name}`
            if (!category.desk.slug2 && index === 0) return ""
            else
              return (
                <li key={`categoryList-${index}`}>
                  <Link
                    activeClassName="active"
                    to={linkBuilder.deskCategory(category)}
                  >
                    {name}
                  </Link>
                </li>
              )
          })}
      </ul>
    </div>
  )
}

export default CategoryList
