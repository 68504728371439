// extracted by mini-css-extract-plugin
export var articleLink = "articles-grid-alt-module--articleLink--f5db3";
export var articleList = "articles-grid-alt-module--articleList--09cb8";
export var featuredImage = "articles-grid-alt-module--featuredImage--218a5";
export var featuredInfo = "articles-grid-alt-module--featuredInfo--8307c";
export var featuredInfoWrapper = "articles-grid-alt-module--featured-info-wrapper--cf44c";
export var featuredTitle = "articles-grid-alt-module--featuredTitle--da716";
export var gridTitle = "articles-grid-alt-module--gridTitle--ad75b";
export var hubFeatured = "articles-grid-alt-module--hub-featured--6ab03";
export var hubFeaturedImageWrapper = "articles-grid-alt-module--hub-featured-image-wrapper--3d68a";
export var hubFeaturedTime = "articles-grid-alt-module--hub-featured-time--d4f62";
export var hubListWrapper = "articles-grid-alt-module--hub-list-wrapper--3551d";
export var hubWrapper = "articles-grid-alt-module--hubWrapper--78354";
export var moreLink = "articles-grid-alt-module--moreLink--4a34d";
export var placeholderImage = "articles-grid-alt-module--placeholderImage--7657e";