import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import get from "lodash/get"

import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import Header from "../../components/header/header"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import Sponsor from "../../components/sponsor/sponsor"
import RichTextContent from "../../components/rich-text-content/rich-text-content"
import CategoryList from "../../components/category-list/category-list"
import ArticleGridAlt from "../../components/articles-grid-alt/articles-grid-alt"
import SideColumn from "../../components/side-column/side-column"
import Footer from "../../components/footer/footer"

import { uniqueArrayByParam } from "../../modules/helpers"

/* Styles */
import {
  MainAndSideWrapper,
  DeskContent,
  intro,
  deskArticlesGrid,
  DeskAside,
} from "./desk.module.css"

class DeskTemplate extends React.Component {
  render() {
    const desk = get(this.props, "data.contentfulDesk")
    const categories = get(desk, "deskcategory")
    const relatedCategories = get(desk, "relatedCategories")
    const deskDescription = get(
      desk,
      "description.childMarkdownRemark.rawMarkdownBody"
    )
    const desks = get(this.props, "pageContext.desks")
    const featuredCategories = get(this.props, "pageContext.featuredCategories")
    const currentPage = get(this.props, "pageContext.currentPage", 0)
    const deskUrl = `https://www.mkbservicedesk.nl${linkBuilder.desk(
      desk,
      currentPage
    )}`

    return (
      <Layout location={this.props.location}>
        <Seo
          title={
            (desk.pageTitle || desk.name) +
            `${currentPage > 1 ? " (Pagina: " + currentPage + ")" : ""}`
          }
          description={
            desk.metaDescription ||
            deskDescription ||
            `In deze desk vertellen we je alles over ${
              desk.name
            } en lees je het laatste nieuws! ${
              currentPage > 1 ? "(Pagina: " + currentPage + ")" : ""
            }`
          }
          canonicalUrl={desk.canonicalUrl}
          noIndex={desk.noIndex}
        />
        <SocialMeta
          title={desk.seoTitle || desk.name}
          description={desk.seoDescription || deskDescription}
          url={linkBuilder.desk(desk)}
          image={desk.image && desk.image.gatsbyImageData}
        />
        <Helmet>
          <link rel="canonical" href={deskUrl} />
        </Helmet>
        <Header
          desks={desks}
          data={{ ...desk, body: null }}
          backgroundImage={desk.image.gatsbyImageData}
        />
        <main className="main desk">
          <div className="container">
            <Breadcrumb desk={desk} desks={desks} />
            <div className={MainAndSideWrapper}>
              <section className={DeskContent}>
                {desk.sponsor && <Sponsor sponsor={desk.sponsor} />}

                {categories && (
                  <CategoryList title="Snel naar:" categories={categories} />
                )}

                {relatedCategories && (
                  <CategoryList
                    title="Gerelateerd:"
                    categories={relatedCategories}
                    showDesk={true}
                  />
                )}

                {deskDescription && !desk.body && (
                  <p className={intro}>{deskDescription}</p>
                )}

                {desk.body && (
                  <RichTextContent
                    content={desk.body}
                    pageContentfulId={desk.contentful_id}
                    pageTitle={desk.name}
                    displayPagePath={linkBuilder.desk(desk, currentPage)}
                  ></RichTextContent>
                )}

                <section className={deskArticlesGrid}>
                  {featuredCategories &&
                    Object.entries(featuredCategories).map(
                      ([_, catArticles], index) => {
                        let articleGridArticles = []
                        if (catArticles.category?.featuredArticles)
                          articleGridArticles =
                            catArticles.category?.featuredArticles
                        articleGridArticles.push(
                          ...catArticles.articles.data.allContentfulArticle
                            .nodes
                        )
                        let uniqueArticleArray = uniqueArrayByParam(
                          articleGridArticles,
                          "contentful_id"
                        )

                        if (uniqueArticleArray && uniqueArticleArray.length > 0)
                          return (
                            <ArticleGridAlt
                              key={`article-grid-alt-${index}`}
                              articles={uniqueArticleArray}
                              title={catArticles.category.name}
                              link={linkBuilder.deskCategory(
                                catArticles.category
                              )}
                              linkText={`Meer ${catArticles.category.name}`}
                            />
                          )
                        else return <></>
                      }
                    )}
                </section>
              </section>
              <div className={DeskAside}>
                <SideColumn categories={categories} />
              </div>{" "}
              {/* DeskAside */}
            </div>{" "}
            {/* MainAndSideWrapper */}
          </div>
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default DeskTemplate

export const pageQuery = graphql`
  query DeskBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulDesk(slug: { eq: $slug }) {
      name
      subtitle
      slug
      slug2
      legacyId
      pageTitle
      metaDescription
      canonicalUrl
      noIndex
      socialTitle
      socialDescription
      image {
        title
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      deskcategory {
        name
        legacyId
        slug
        slug2
        priority
        desk {
          slug2
        }
      }
      relatedCategories {
        name
        legacyId
        slug
        slug2
        desk {
          name
          slug2
        }
      }
      sponsor {
        name
        url
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          title
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 250)
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      body {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            title
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          ... on ContentfulArticle {
            contentful_id
            id
            legacyId
            title
            slug
            slug2
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
          ... on ContentfulDesk {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDeskCategory {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDownload {
            contentful_id
            id
            downloadType
            slug
            asset {
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            formFields
            title
            sponsor {
              name
            }
            image {
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
          ... on ContentfulOutgrow {
            contentful_id
            outgrowId
          }
          ... on ContentfulSponsor {
            contentful_id
            name
            url
          }
          ... on ContentfulSpotify {
            contentful_id
            title
            link
          }
          ... on ContentfulTable {
            contentful_id
            tableData {
              id
              headerColumn
              headerRow
              tableData
            }
          }
          ... on ContentfulVideo {
            contentful_id
            url
          }
        }
      }
    }
  }
`
