import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  hubWrapper,
  gridTitle,
  hubFeatured,
  featuredImage,
  placeholderImage,
  featuredInfoWrapper,
  featuredInfo,
  featuredTitle,
  articleList,
  articleLink,
  moreLink,
} from "./articles-grid-alt.module.css"
import linkBuilder from "../../modules/linkBuilder"
import { readTimeFromRichText } from "../../modules/helpers"

const ArticlesGridAlt = ({ title, link, linkText, articles }) => {
  /**
   * If there is no featured article defined,
   * use the first article from the array
   */

  return (
    <div className={hubWrapper}>
      <div className="hub">
        <h1 className={gridTitle}>{title}</h1>

        <article className={hubFeatured}>
          <Link to={linkBuilder.article(articles[0])}>
            <div className={featuredImage}>
              {articles[0].image && (
                <GatsbyImage
                  image={articles[0].image.gatsbyImageData}
                  alt={
                    articles[0].image.title
                      ? articles[0].image.title
                      : articles[0].title
                  }
                />
              )}
              {!articles[0].image && (
                <img
                  className={placeholderImage}
                  alt={articles[0].title}
                  src="https://images.ctfassets.net/8kzekgmzhrvm/32zpVsrLSwTo9LVxUIjHIM/ecde2a45d6437064cdd45ee71574f463/placeholder3?h=250"
                />
              )}
            </div>

            <div className={featuredInfoWrapper}>
              <div className={featuredInfo}>
                <span className="featuredReadTime">
                  Leestijd: {readTimeFromRichText(articles[0].body.raw)} min
                </span>
              </div>

              <div className={featuredTitle}>
                <h1>{articles[0].title}</h1>
              </div>
            </div>
          </Link>
        </article>

        <section className={articleList}>
          {articles
            .filter((_, index) => index >= 1 && index <= 6)
            .map((article, index) => {
              return (
                <Link
                  key={`index-${index}`}
                  to={linkBuilder.article(article)}
                  className={articleLink}
                >
                  {article.title}
                </Link>
              )
            })}
        </section>

        {link && (
          <Link to={link} className={moreLink}>
            {linkText ? linkText : "meer"}
          </Link>
        )}
      </div>
    </div>
  )
}

export default ArticlesGridAlt
